import { mapGetters } from "vuex";
import store from "@/store";

export default {
  data() {
    return {
      attemptToNavigateWithUnsavedChanges: false,
    };
  },
  computed: {
    ...mapGetters({
      unsavedChanges: "unsaved/unsavedChanges",
    }),
  },
  watch: {
    unsavedChanges() {
      if (this.attemptToNavigateWithUnsavedChanges)
        this.$emit("changeView", "list");
    },
  },
  methods: {
    setUnsavedChanges(isChanged) {
      store.dispatch("unsaved/setChanges", isChanged);
    },
    navigateTo() {
      if (this.unsavedChanges) {
        store.dispatch("unsaved/navigateTo", {
          disableModal: false,
          target: this.$route.name,
        });
        this.attemptToNavigateWithUnsavedChanges = true;
      } else this.$emit("changeView", "list");
    },
  },
};
