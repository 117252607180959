<template>
<div
    class="wrapper"
    :ref="ref"
    :id="ref"
    @click="togglePopUpHandler"
    tabindex="-1">
    <Icon
        :path="mdiAlertCircle"
        :size="16" />
</div>
<Teleport
    to="body">
    <div
        :style="{ ...position }"
        class="info-pop-up"
        v-if="showPopup">
        <p>{{ text }}</p>
    </div>
</Teleport>
</template>

<script>
import {
    mdiAlertCircle
} from "@mdi/js";

export default {
    data() {
        return {
            showPopup: false,
            mdiAlertCircle,
            position: {},
            ref: null,
        };
    },
    props: {
        text: {
            type: String,
            default: "",
        },
    },
    watch: {
        showPopup: {
            handler(newValue) {

                if (newValue) {
                    const {
                        left,
                        top,
                        right,
                        bottom
                    } = this.$refs[this.ref].getBoundingClientRect();

                    const viewHeight = window.innerHeight;
                    const viewWidth = window.innerWidth;

                    if (viewHeight - bottom < 250) {
                        this.position['bottom'] = viewHeight - top + 'px'
                    } else {
                        this.position['top'] = bottom + 'px'
                    }

                    if (viewWidth - left < 250) {
                        this.position['left'] = right - 140 + 'px'
                    } else {
                        this.position['left'] = left + 'px'
                    }
                }
                return document.querySelector('body').style.overflow = newValue ? 'hidden' : 'unset'
            },
            immediate: true,
            deep: true
        },
    },
    methods: {
        togglePopUpHandler() {
            this.showPopup = !this.showPopup;
        },
        clickOutsideListener(event) {
            if (this.showPopup) {
                const component = this.$refs[this.ref]
                if (!event.composedPath().includes(component)) {
                    this.showPopup = false;
                }
            }
        }
    },
    mounted() {
        const id = Math.floor(Math.random() * 100);
        this.ref = 'chip' + id;
        window.addEventListener('click', this.clickOutsideListener);
    },

    beforeUnmount() {
        window.removeEventListener('click', this.clickOutsideListener);
    }
};
</script>

<style lang="scss">
.wrapper {
    position: relative;
    height: 16px;

    .mdi-icon {
        font-size: 16px;
        cursor: pointer;
    }
}

.info-pop-up {
    position: fixed;
    padding: 1rem;
    width: 150px;
    background: #fff;
    color: #000;
    font-weight: 500;
    border-radius: $card_radius;
    box-shadow: $card_shadow;
    z-index: 9999;

    p {
        margin: 0;
        padding: 0;
        font-size: 0.785rem;
    }
}
</style>
