<template>
  <div class="chips">
    <template v-if="this.params.value.length > 0">
      <span class="chips__qty">({{ this.params.value.length }})</span>
      <span class="chips__dot"></span>
      <span
        class="chip"
        v-for="value, index in this.params.value"
        :key="value + index"
      >
        {{ upperCamelCaseToString(value) }}
      </span>
    </template>
    <template v-else>-</template>
  </div>
</template>

<script>
import { upperCamelCaseToString } from "@/helpers/stringFormatter.js";

export default {
  methods: {
    upperCamelCaseToString
  }
}
</script>

<style lang="scss">
.chips {
  @include flex-initial($alignItems: center, $justifyContent: center, $gap: 0.25rem);

  &__qty {
    font-size: 0.75rem;
    color: gray;
    display: inline-block;

  }

  &__dot {
    display: inline-block;
    width: 2px;
    height: 2px;
    border-radius: 50%;
    background: lightgray;
  }
}
</style>
